// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: auto;
    overflow: hidden;
}

.slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
    cursor: grab;
}

.slide {
    min-width: 100%;
}

.slide img {
    display: block;
}

.dots {
    text-align: center;
    margin-top: 10px;
}

.dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 5px;
    background-color: rgba(0, 0, 0, 0.47);
    border-radius: 50%;
    cursor: pointer;
}

.dot.active {
    background-color: #95d1d0;
}

.slider-container:active .slider {
    cursor: grabbing;
}`, "",{"version":3,"sources":["webpack://./src/styles/ForPages/AddCounseling/Slider.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sCAAsC;IACtC,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,qCAAqC;IACrC,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".slider-container {\n    position: relative;\n    width: 100%;\n    max-width: 600px;\n    margin: auto;\n    overflow: hidden;\n}\n\n.slider {\n    display: flex;\n    transition: transform 0.5s ease-in-out;\n    cursor: grab;\n}\n\n.slide {\n    min-width: 100%;\n}\n\n.slide img {\n    display: block;\n}\n\n.dots {\n    text-align: center;\n    margin-top: 10px;\n}\n\n.dot {\n    display: inline-block;\n    width: 10px;\n    height: 10px;\n    margin: 5px;\n    background-color: rgba(0, 0, 0, 0.47);\n    border-radius: 50%;\n    cursor: pointer;\n}\n\n.dot.active {\n    background-color: #95d1d0;\n}\n\n.slider-container:active .slider {\n    cursor: grabbing;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
