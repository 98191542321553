// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-wrapper {
    width: 78vw;
    height: 63vh;
    margin: 7vh auto 0 auto;
}

.account-switchBtns-container {
    width: 100%;
    height: fit-content;
    gap: 7px;
    display: flex;
}

.account-switchBtns-container button {
    flex: 1 1;
    padding: 1.38rem 0;
    align-items: center;
    justify-content: center;
    border-top: 3px solid #BAB4B4;
    border-left: 3px solid #BAB4B4;
    border-right: 3px solid #BAB4B4;
    border-bottom: none;
    border-radius: 0.625rem 0.625rem 0 0;
    font-weight: bold;
    font-size: clamp(1rem, 2vw, 20px);
    cursor: pointer;
}

.pickIcon {
    transform: translate(0, -10%);
    vertical-align: middle;
    margin-right: 5px;
    width: 1.6vw;
    height: 2.3vh;
}`, "",{"version":3,"sources":["webpack://./src/styles/ForPages/AccountSettings/AccountSettings.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,QAAQ;IACR,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,8BAA8B;IAC9B,+BAA+B;IAC/B,mBAAmB;IACnB,oCAAoC;IACpC,iBAAiB;IACjB,iCAAiC;IACjC,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,sBAAsB;IACtB,iBAAiB;IACjB,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".account-wrapper {\n    width: 78vw;\n    height: 63vh;\n    margin: 7vh auto 0 auto;\n}\n\n.account-switchBtns-container {\n    width: 100%;\n    height: fit-content;\n    gap: 7px;\n    display: flex;\n}\n\n.account-switchBtns-container button {\n    flex: 1;\n    padding: 1.38rem 0;\n    align-items: center;\n    justify-content: center;\n    border-top: 3px solid #BAB4B4;\n    border-left: 3px solid #BAB4B4;\n    border-right: 3px solid #BAB4B4;\n    border-bottom: none;\n    border-radius: 0.625rem 0.625rem 0 0;\n    font-weight: bold;\n    font-size: clamp(1rem, 2vw, 20px);\n    cursor: pointer;\n}\n\n.pickIcon {\n    transform: translate(0, -10%);\n    vertical-align: middle;\n    margin-right: 5px;\n    width: 1.6vw;\n    height: 2.3vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
