// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.criteriaStyle {
    width: 33.7%;
    height: 100%;
    text-align: left;
    font-size: clamp(1rem, 1.5vw, 18px);
    padding-left: 1.875vw;
    outline: none;
    border-radius: 1.25rem;
    border: 2px solid #aed391;
}

.icon-wrapper {
  height: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: linear-gradient(
    270deg,
    #aed391 -1.77%,
    #a9d3a1 20.55%,
    #a5d2ab 35.26%,
    #a2d2b3 46.42%,
    #9dd2c1 65.69%,
    #99d1cc 94.6%,
    #95d1d9 99.67%
  );
  border-radius: 50%;
  @media (max-width: 768px) {
    margin-left: 0px;
    height: 80%;
  }
}

#plusIcon-wrapper {
  @media (max-width: 768px) {
    margin-left: 4px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/ForComps/SearchBar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,mCAAmC;IACnC,qBAAqB;IACrB,aAAa;IACb,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf;;;;;;;;;GASC;EACD,kBAAkB;EAClB;IACE,gBAAgB;IAChB,WAAW;EACb;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".criteriaStyle {\n    width: 33.7%;\n    height: 100%;\n    text-align: left;\n    font-size: clamp(1rem, 1.5vw, 18px);\n    padding-left: 1.875vw;\n    outline: none;\n    border-radius: 1.25rem;\n    border: 2px solid #aed391;\n}\n\n.icon-wrapper {\n  height: 100%;\n  aspect-ratio: 1 / 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  background: linear-gradient(\n    270deg,\n    #aed391 -1.77%,\n    #a9d3a1 20.55%,\n    #a5d2ab 35.26%,\n    #a2d2b3 46.42%,\n    #9dd2c1 65.69%,\n    #99d1cc 94.6%,\n    #95d1d9 99.67%\n  );\n  border-radius: 50%;\n  @media (max-width: 768px) {\n    margin-left: 0px;\n    height: 80%;\n  }\n}\n\n#plusIcon-wrapper {\n  @media (max-width: 768px) {\n    margin-left: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
