// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/Images/img_main_background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-bottom: 115px;
}

.main-content > p {
  margin-top: 74px;
  font-size: 32px;
}

.main-logo-container > img {
  margin-top: 76px;
  width: 327px;
  height: 242px;
}

@media (max-width: 768px) {
  .main-content > p {
    margin-top: 75px;
    font-size: 15px;
  }
  .main-logo-container > img {
    margin-top: 95px;
    width: 209px;
    height: 170px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/ForPages/Main/Main.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yDAA4D;EAC5D,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;EAC3B,aAAa;EACb,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE;IACE,gBAAgB;IAChB,eAAe;EACjB;EACA;IACE,gBAAgB;IAChB,YAAY;IACZ,aAAa;EACf;AACF","sourcesContent":[".main-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-image: url(\"/src/Images/img_main_background.jpg\");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  height: 100vh;\n  position: relative;\n  overflow: hidden;\n}\n\n.main-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  margin-bottom: 115px;\n}\n\n.main-content > p {\n  margin-top: 74px;\n  font-size: 32px;\n}\n\n.main-logo-container > img {\n  margin-top: 76px;\n  width: 327px;\n  height: 242px;\n}\n\n@media (max-width: 768px) {\n  .main-content > p {\n    margin-top: 75px;\n    font-size: 15px;\n  }\n  .main-logo-container > img {\n    margin-top: 95px;\n    width: 209px;\n    height: 170px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
