// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/GmarketSansTTFMedium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "Gmarket Sans";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

input[type="text"], input::placeholder, button, select {
    font-family: 'Gmarket Sans', sans-serif;
}

body {
    margin: 0;
    font-family: "Gmarket Sans";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.goto-back {
    display: none;
}

.goto-back img {
    width: 25px;
    height: 23px;
}

.loading-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading-wrapper img {
    width: 12rem;
    height: 12rem;
}

@media (max-width: 768px) {
    .goto-back {
        position: absolute;
        top: 2.3vh;
        left: 4vw;
        cursor: pointer;
        display: block;
    }
}

* {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,+DAA+D;AACnE;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,SAAS;IACT,2BAA2B;IAC3B,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI;QACI,kBAAkB;QAClB,UAAU;QACV,SAAS;QACT,eAAe;QACf,cAAc;IAClB;AACJ;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,+EAA+E;AACnF","sourcesContent":["@font-face {\n    font-family: \"Gmarket Sans\";\n    src: url(\"./fonts/GmarketSansTTFMedium.ttf\") format(\"truetype\");\n}\n\ninput[type=\"text\"], input::placeholder, button, select {\n    font-family: 'Gmarket Sans', sans-serif;\n}\n\nbody {\n    margin: 0;\n    font-family: \"Gmarket Sans\";\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\n.goto-back {\n    display: none;\n}\n\n.goto-back img {\n    width: 25px;\n    height: 23px;\n}\n\n.loading-wrapper {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n\n.loading-wrapper img {\n    width: 12rem;\n    height: 12rem;\n}\n\n@media (max-width: 768px) {\n    .goto-back {\n        position: absolute;\n        top: 2.3vh;\n        left: 4vw;\n        cursor: pointer;\n        display: block;\n    }\n}\n\n* {\n    box-sizing: border-box;\n}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
