// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-panel-container {
    display: flex;
    flex-direction: row;
    margin: 6.3vh auto 0 auto;
    width: 78.2vw;
    height: 12.79vh;
    gap: 1.3%;
}

.drugButtons-container {
    width: 77.8vw;
    height: 4.6vh;
    margin: 1.4vh auto;
    display: flex;
    justify-content: space-between;
}

.drugButtons-container > div > button {
    width: 11.6vw;
    height: 100%;
    font-size: clamp(1rem, 1.5vw, 18px);
    font-weight: bold;
    border-radius: 5px;
    white-space: nowrap;
    text-overflow: clip;
    color: white;
    background-color: #aed391;
    border: none;
    cursor: pointer;
}
.left-btn {
    display: flex;
}

.right-btns {
    display: flex;
    justify-self: flex-end;
    gap: 1.4vw;
}

.tag {
    margin-top: 3vh;
    margin-bottom: 2vh;
    text-align: center;
    font-size: clamp(1rem, 1.5vw, 18px);
}
.tag span:first-child{
    font-weight: bold;
    vertical-align: baseline;
}
.tag span:last-child{
    color: #3F2FF2;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ForPages/Drugs/Drugs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,aAAa;IACb,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mCAAmC;IACnC,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,eAAe;AACnB;AACA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;AACd;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,mCAAmC;AACvC;AACA;IACI,iBAAiB;IACjB,wBAAwB;AAC5B;AACA;IACI,cAAc;AAClB","sourcesContent":[".ui-panel-container {\n    display: flex;\n    flex-direction: row;\n    margin: 6.3vh auto 0 auto;\n    width: 78.2vw;\n    height: 12.79vh;\n    gap: 1.3%;\n}\n\n.drugButtons-container {\n    width: 77.8vw;\n    height: 4.6vh;\n    margin: 1.4vh auto;\n    display: flex;\n    justify-content: space-between;\n}\n\n.drugButtons-container > div > button {\n    width: 11.6vw;\n    height: 100%;\n    font-size: clamp(1rem, 1.5vw, 18px);\n    font-weight: bold;\n    border-radius: 5px;\n    white-space: nowrap;\n    text-overflow: clip;\n    color: white;\n    background-color: #aed391;\n    border: none;\n    cursor: pointer;\n}\n.left-btn {\n    display: flex;\n}\n\n.right-btns {\n    display: flex;\n    justify-self: flex-end;\n    gap: 1.4vw;\n}\n\n.tag {\n    margin-top: 3vh;\n    margin-bottom: 2vh;\n    text-align: center;\n    font-size: clamp(1rem, 1.5vw, 18px);\n}\n.tag span:first-child{\n    font-weight: bold;\n    vertical-align: baseline;\n}\n.tag span:last-child{\n    color: #3F2FF2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
